.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  width: 100%;
  padding: 9px 0 8px;

  label {
    display: flex;
    align-items: center;
    width: 100%;

    input,
    textarea {
      flex-grow: 1;
      min-height: 50px;
      padding: 12px 8px;

      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: $color-nero;

      border: none;
      background-color: $color-white-smoke;
      outline: none;
      box-shadow: 0 0 0 1px $color-default-white;

      transition: box-shadow $trans-default;

      &::placeholder {
        position: relative;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        color: $color-bali-hai;
      }

      &:hover {
        box-shadow: 0 0 0 1px rgba($color-default-black, 0.5);
      }

      &:focus {
        box-shadow: 0 0 0 1px rgba($color-default-black, 0.5);
      }
    }
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: -5px;

    margin: 0;
    font-size: 12px;
    line-height: 1;
    color: $color-alizarin;

    opacity: 0;

    transition: opacity $trans-default;
  }

  &.is-invalid {
    input {
      border: 1px solid $color-alizarin;

      &:focus,
      &:hover {
        border: 1px solid rgba($color-alizarin, 0.5);
      }
    }

    .custom-input__error {
      opacity: 1;
    }
  }

  /*&.is-valid {
    input {
      border: 2px solid $color-malachite;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-malachite, 0.5);
      }
    }
  }*/

  &__label {
    position: absolute;
    top: 9px;
    left: 0;

    margin: 0;
    padding: 12px 8px;
    opacity: 1;
    transition: opacity $trans-default;
    pointer-events: none;
    color: $color-matterhorn;

    &::after {
      content: "*";
      padding-left: 5px;
      color: $color-alizarin;
    }
  }

  input:focus ~ .custom-input__label {
    opacity: 0;
  }
}
