.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-scrollbar {
  position: relative;
  height: 1px;
  flex-grow: 1;

  .scrollable {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba($color-bali-hai, 0.45) transparent;
    overflow: overlay;
    height: 100%;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border: solid 8px transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px rgba($color-bali-hai, 0.45);
      border: solid 3px transparent;
      border-radius: 6px;
    }
  }

  &::before,
  &::after {
    position: absolute;
    right: 10px;
    left: 0;
    height: 40px;
    content: "";
    bottom: 0;
    background: linear-gradient(180deg, rgba($color-default-white, 0) 0%, $color-default-white 100%);
  }

  &::before {
    top: 0;
    z-index: 1;
    background: linear-gradient(180deg, $color-default-white 0%, rgba($color-default-white, 0) 100%);
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}
