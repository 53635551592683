.footer {
  &__bottom {
    position: relative;
    padding: 45px 0 30px;
    color: $color-default-white;
    background: linear-gradient(92.32deg, #0a1f4f 44.85%, #0a3c61 101.18%);
    overflow: hidden;

    @include vp-767 {
      padding: 28px 0 40px;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  &__bottom-bg {
    position: absolute;
    bottom: 0;
    max-height: 100%;
    pointer-events: none;

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
      transform: scale(-1, 1);

      @include vp-1023 {
        display: none;
      }
    }

    svg {
      width: 391px;
      height: 367px;
    }

    @include vp-767 {
      display: none;
    }
  }

  &__nav-list {
    display: grid;
    grid-template-columns: minmax(min-content, 170px) minmax(min-content, 167px) minmax(
        min-content,
        160px
      ) minmax(min-content, 150px) minmax(min-content, 160px) repeat(
        2,
        minmax(min-content, 170px)
      );
    grid-column-gap: 28px;
    margin: 0;
    padding: 0;
    list-style: none;

    @include vp-1023 {
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 20px;
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;
      grid-row-gap: 0;
    }
  }

  &__nav-item {
    @include vp-767 {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &__nav-title {
    margin: 0;
  }

  &__nav-button {
    margin-bottom: 31px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $color-default-white-08;

    @include vp-767 {
      width: 100%;
      margin-bottom: 0;
      padding: 12px 0 11px;
    }
  }

  &__nav-sublevel-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__nav-sublevel-item {
    position: relative;
    padding-left: 14px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.2;
    transition: opacity $trans-default;

    @include vp-767 {
      margin-bottom: 11px;
    }

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 7px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba($color-default-white, 0.7);
    }

    &:hover,
    &:focus {
      .footer__nav-sublevel-link {
        opacity: 0.7;
      }
    }

    &:active {
      opacity: 0.5;
    }
  }

  &__nav-sublevel-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: $color-default-white;
    transition: opacity $trans-default;

    &--bold {
      font-weight: 700;
      margin-bottom: 16px;

      @include vp-767 {
        margin-bottom: 11px;
      }
    }
  }

  &__nav-sublevel-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.2;
  }

  &__copyright {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 13px;

    @include vp-1023 {
      flex-direction: column;
      padding-top: 20px;
    }

    p {
      max-width: 500px;
      margin: 0;
      margin-right: 8px;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.3;
      color: $color-default-white-08;
      user-select: none;
      pointer-events: none;

      @include vp-1279 {
        max-width: 290px;
      }

      @include vp-1023 {
        max-width: 100%;
        margin-bottom: 15px;
      }

      @include vp-767 {
        max-width: 290px;
      }

      &:last-child {
        margin-right: 0;
        white-space: nowrap;
      }
    }
  }

  &__copyright-author {
    display: flex;
    align-items: center;

    @include vp-1023 {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__copyright-link {
    margin-left: 8px;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.3;
    color: $color-default-white;
    transition: opacity $trans-default;
    cursor: pointer;
    pointer-events: all;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .socials {
    position: relative;
    padding: 13px 0 0 3px;
    z-index: 2;

    @include vp-767 {
      padding: 0;
      margin-bottom: 11px;
    }
  }
}
