.docs-list {
  list-style: none;
  margin: 0;
  padding: 25px 9px 23px 85px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include vp-767 {
    padding: 23px 9px 19px 52px;
  }

  &__link {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    font-family: $ff-ubuntu;
    color: $color-nero;
    transition: color $trans-default;

    svg {
      position: absolute;
      left: -34px;
      color: $color-bali-hai;
      fill: none;
      transition: color $trans-default;

      @include vp-767 {
        left: -27px;
        top: -2px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-alizarin;

        svg {
          color: inherit;
        }
      }
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 120%;
      align-items: flex-start;
    }
  }

  &__item:not(:last-child) {
    margin: 0 0 17px;

    @include vp-767 {
      margin: 0 0 20px;
    }
  }
}
