.events {
  position: relative;
  padding-top: 100px;
  padding-bottom: 120px;

  @include vp-767 {
    padding: 37px 0 40px;
  }

  &__bg {
    position: absolute;
    top: 58%;
    transform: translateY(-50%);
    right: 0;
    z-index: -1;

    img {
      width: 621px;
      height: 356px;
    }

    @include vp-1439 {
      display: none;
    }
  }

  &__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 28px;
    margin: 0;
    margin-top: 30px;
    padding: 0;
    list-style: none;
    z-index: 2;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }

    @include vp-767 {
      margin-top: 12px;
    }
  }

  &__item {
    position: relative;
    cursor: pointer;

    &:hover,
    &:focus {
      .events__button {
        background-color: $color-default-white;
        border-radius: 2px solid $color-default-white;
        color: $color-dark-cerulean;
      }
    }
  }

  &__img-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 2px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      min-height: 180px;
      max-height: 220px;
      object-fit: cover;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      background-color: rgba($color-tangaroa, 0.4);
      z-index: 1;
    }
  }

  &__content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    bottom: 20px;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;

    @include vp-767 {
      bottom: 15px;
      padding: 0 12px;
    }
  }

  &__title {
    font-family: $ff-ubuntu;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    color: $color-default-white;
    margin: 0;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    /* stylelint-disable-next-line */
    -webkit-box-orient: vertical;
    /* stylelint-disable-next-line */
    -webkit-line-clamp: 1;
    overflow: hidden;

    @include vp-767 {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__button {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    color: $color-default-white;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.25);
    transition: background-color $trans-default, color $trans-default;

    @include vp-767 {
      display: none;
    }

    svg {
      width: 13px;
      height: 10px;
      fill: none;
      transition: stroke $trans-default;
    }
  }
}
