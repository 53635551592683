.content-block {
  .title {
    margin-bottom: 32px;
  }

  .strong {
    margin-bottom: 28px;
    font-size: 19px;
    line-height: 1.5;

    strong {
      font-weight: 500;
    }

    @include vp-767 {
      font-size: 15px;
      line-height: 1.52;
    }
  }

  b {
    font-weight: 500;
  }

  .title--h4 {
    @include vp-767 {
      padding-top: 15px;
      margin-bottom: 12px;
    }
  }

  p {
    margin: 0;
    margin-bottom: 29px;
    line-height: 1.6;

    @include vp-767 {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.58;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .blockquote {
    margin-bottom: 27px;

    @include vp-767 {
      margin-bottom: 13px;
    }
  }

  ol,
  ul {
    padding: 0;
    padding-left: 39px;
    margin: 0 0 34px;
    list-style: none;

    @include vp-767 {
      margin: 0 0 27px;
      padding-left: 20px;
    }

    li {
      position: relative;
    }

    li::before {
      position: absolute;
      top: 0;
      left: -19px;
      color: $color-dark-cerulean;
    }
  }

  ul {
    @include vp-767 {
      padding-top: 12px;
    }

    li::before {
      content: "\2022";
      font-weight: 700;
    }
  }

  ol {
    counter-reset: myCounter;
    padding-top: 3px;

    li::before {
      counter-increment: myCounter;
      content: counter(myCounter) ".";
    }

    @include vp-767 {
      padding-top: 10px;
    }
  }

  li {
    margin-bottom: 10px;
    line-height: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__figure {
    margin: 0 0 14px;

    @include vp-767 {
      margin: 0 0 22px;
    }
  }

  &__caption {
    padding: 10px 0;
    font-size: 12px;
    color: $color-bali-hai;

    @include vp-767 {
      padding: 5px 0;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;

    @include vp-1023 {
      grid-column-gap: 12px;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      grid-column-gap: 16px;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  &__aspect-ratio {
    position: relative;
    height: 0;
    padding-top: 332px / 465px * 100%;
    background-color: $color-bali-hai;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  &__float-img {
    margin-left: 30px;
    margin-bottom: 30px;
    max-width: 50%;
    float: right;

    @include vp-767 {
      margin-left: unset;
      float: unset;
      width: 100%;
      max-width: unset;
      margin-bottom: 33px;
    }
  }

  .info-block {
    margin: 28px 0;

    @include vp-767 {
      margin-bottom: 30px;
    }
  }
}
