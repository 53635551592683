.footer-video {
  padding: 100px 0 117px;
  color: $color-default-white;
  background-color: $color-dark-cerulean;
  background-image: url("../img/content/footer/video-bg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

  @include retina {
    background-image: url("../img/content/footer/video-bg@2x.png");
  }

  @include vp-1023 {
    padding: 38px 0 32px;
  }

  .title--h3 {
    @include vp-1023 {
      font-size: 22px;
    }
  }

  &__wrapper {
    padding-top: 30px;

    @include vp-1023 {
      padding-top: 15px;
    }
  }
}
