.footer-invite {
  position: relative;
  padding: 70px 0;

  @include vp-767 {
    padding: 40px 0;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    width: 31%;

    @include vp-1279 {
      display: none;
    }

    &--left {
      left: 0;
      top: 0;

      img {
        object-position: right;
      }
    }

    &--right {
      right: 0;
      bottom: 0;

      img {
        object-position: left;
      }
    }

    img {
      width: 100%;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 42px;
    font-weight: 700;
    font-size: 50px;
    line-height: 1;
    color: $color-nero;
    text-align: center;

    @include vp-1023 {
      font-size: 36px;
    }

    @include vp-767 {
      margin-bottom: 15px;
      font-size: 22px;
      text-align: left;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    padding: 0 113px;

    @include vp-1279 {
      padding: 0;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      grid-column-gap: 15px;
    }
  }

  &__form {
    padding: 12px 22px 19px 20px;
    background-color: $color-default-white;
    box-shadow: 0 3px 30px rgba(5, 88, 140, 0.08);
    border-radius: 2px;

    .btn {
      width: 100%;
      margin-top: 19px;

      @include vp-767 {
        margin-top: 8px;
      }
    }
  }

  &__img {
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    height: 100%;

    @include vp-767 {
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include vp-767 {
        height: auto;
      }
    }
  }
}
