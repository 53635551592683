.breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;

  font-size: 12px;
  line-height: 1.2;
  opacity: 0.6;

  @include vp-767 {
    font-size: 11px;
    line-height: 16px;
  }

  &__item {
    display: inline;

    &:not(:last-child)::after {
      content: " – ";
      letter-spacing: 0.17em;
    }

    &:last-child a {
      pointer-events: none;
    }
  }

  &__item span {
    padding-left: 5px;
    padding-right: 4px;
  }
}
