.title {
  margin: 0;
  line-height: 1;

  &--h1 {
    font-size: 50px;
    line-height: 65px;

    @include vp-767 {
      font-size: 33px;
      line-height: 36px;
    }
  }

  &--h2 {
    font-size: 34px;

    @include vp-767 {
      font-size: 22px;
    }
  }

  &--h3 {
    font-size: 25px;

    @include vp-767 {
      font-size: 22px;
    }
  }

  &--h4 {
    font-size: 19px;
    line-height: 1.5;

    @include vp-767 {
      font-size: 15px;
    }
  }

  &--medium {
    font-weight: 500;
    line-height: 1.25;
  }
}
