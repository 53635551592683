.custom-toggle {
  position: relative;

  display: flex;
  width: 100%;
  padding: 10px 0;

  @include vp-767 {
    padding: 9px 0;
  }

  &__error {
    position: absolute;
    bottom: -5px;
    left: 0;

    font-size: 12px;
    line-height: 1;
    color: $color-alizarin;

    opacity: 0;

    transition: opacity $trans-default;
  }

  input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  label {
    position: relative;

    padding-left: 14px;

    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    cursor: pointer;

    &::after {
      content: "*";
      padding-left: 1px;
      color: $color-alizarin;
    }

    span {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &__label {
    color: $color-matterhorn;
  }

  &--checkbox {
    .custom-toggle__icon {
      position: absolute;
      top: -2px;
      left: 0;

      width: 12px;
      height: 12px;

      border: 1px solid $color-sisal;
      border-radius: 2px;
      background-color: $color-oasis;

      transition: border $trans-default;
    }
  }

  &--radio {
    .custom-toggle__icon {
      position: absolute;
      top: 2px;
      left: 0;

      width: 16px;
      height: 16px;

      border: 1px solid rgba($color-default-black, 0.2);
      border-radius: 50%;

      transition: border $trans-default;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: 6px;
        height: 6px;

        color: $color-transparent;

        border-radius: 50%;
        transform: translate(-50%, -50%);

        transition: color $trans-default;
      }
    }
  }

  input:checked + .custom-toggle__icon {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;

      width: 8px;
      height: 8px;
      background-image: url("../img/svg/icon-checkbox.svg");
      background-position: center;
      background-repeat: no-repeat;


      transform: translate(-50%, -50%);
    }
  }

  &.is-invalid {
    .custom-toggle__icon {
      border: 1px solid $color-alizarin;
    }

    .custom-toggle__error {
      opacity: 1;
    }
  }

  /*&.is-valid {
    label span {
      color: $color-eucalyptus;
    }
  }*/
}
