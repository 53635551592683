.socials {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: -15px;
    margin-left: -15px;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-top: 15px;
    margin-left: 15px;
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  &__link {
    display: flex;

    img {
      width: 28px;
      height: 28px;
    }
  }
}
