.news-block {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (max-width: 1100px) {
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }

    @include vp-1023 {
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 0;
    }

    &--col-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--col-3-plus-sidebar {
      grid-template-columns: repeat(3, 1fr);

      @include vp-1023 {
        grid-template-columns: 1fr;
      }

      @include vp-767 {
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
        grid-column-gap: 0;
        margin-bottom: 19px;
      }
    }
  }

  &__item--wide {
    grid-column: 1/-1;
  }
}
