.course {
  width: 100%;

  @include vp-767 {
    margin: 0 0 20px 0;
  }

  &__link {
    position: relative;
    display: block;
  }

  &__image-wrapper {
    position: relative;
    z-index: -1;
    height: 0;
    padding-top: 205px / 300px * 100%;
    background-color: $color-bali-hai;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 100%);
      border-radius: 2px;
      top: 0;
      left: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 20px;

    time {
      padding: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      font-family: $ff-ubuntu;
      color: $color-default-white;
      margin: 0 0 5px 0;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      font-family: $ff-ubuntu;
      color: $color-default-white;
      /* stylelint-disable-next-line */
      display: -webkit-box;
      /* stylelint-disable-next-line */
      -webkit-box-orient: vertical;
      /* stylelint-disable-next-line */
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}

.course--certificates {
  .course__image-wrapper::after {
    display: none;
  }

  .course__image-wrapper {
    padding-top: 211px / 300px * 100%;
  }
}
