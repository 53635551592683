.page-content {
  padding: 70px 0;
  background-color: $color-alice-blue;

  @include vp-767 {
    padding: 40px 0;
  }

  &__wrapper {
    position: relative;
    display: flex;

    @include vp-767 {
      display: block;
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    margin-right: 30px;
    max-width: 23.2%;

    @include vp-1023 {
      max-width: 30%;
      margin-right: 12px;
    }
  }

  &__column {
    flex: 1 1 0;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0;
    margin-bottom: 7px;

    @include vp-767 {
      padding: 0;
      margin-bottom: 20px;
    }

    .subtitle {
      flex: 1;
      margin-right: 16px;

      @include vp-767 {
        min-width: 100%;
        margin-right: 0;
      }
    }

    .views {
      margin-left: 16px;

      @include vp-767 {
        margin-left: 13px;
      }
    }
  }

  .topic {
    margin-bottom: 15px;

    @include vp-767 {
      margin-bottom: 12px;
    }
  }

  .news-block:not(:last-child) {
    margin-bottom: 31px;

    @include vp-767 {
      margin-bottom: 42px;
    }
  }

  .inner-navigation {
    @include vp-1023 {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &--pt-30 {
    padding-top: 30px;
  }
}

.page-content--documents {
  padding: 70px 0 61px;

  @include vp-767 {
    padding: 40px 0 34px;
  }
}

.page-content--study {
  padding: 67px 0 72px;
}
