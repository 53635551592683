.on-photo-label {
  display: block;
  font-family: $ff-ubuntu;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $color-default-white;
  background-color: $color-dark-cerulean;
  border-radius: 2px;
  padding: 4px 6px;
  margin-right: 20px;
}
