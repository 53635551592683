.study {
  &__wrapper {
    align-items: flex-start;

    @include vp-1023 {
      flex-direction: column;
    }
  }

  &__classes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4px;

    @include vp-1023 {
      width: 100%;
      flex: 1 1 100%;
    }
  }
}
