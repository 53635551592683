.news-card {
  position: relative;
  display: flex;
  border-radius: 2px;
  min-height: 190px;
  height: 100%;

  font-size: 14px;
  line-height: 1.3;
  background-color: $color-default-white;

  @include vp-1023 {
    font-size: 12px;
  }

  @include vp-767 {
    flex-direction: column;
    min-height: unset;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    padding: 15px 20px 18px;

    @include vp-1023 {
      padding: 12px;
    }

    @include vp-767 {
      flex: none;
      padding: 13px 11px;
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;

    @include vp-1439 {
      font-size: 14px;
      line-height: 1.2;
    }

    @include vp-1023 {
      font-size: 15px;
      line-height: 1.2;
    }

    @include vp-767 {
      margin-bottom: 6px;
    }
  }

  .subtitle {
    font-size: 12px;
    font-weight: 400;
  }

  &__text {
    margin: 0 0 18px;
    color: $color-matterhorn;

    @include vp-767 {
      margin-bottom: 13px;
    }
  }

  &__link {
    transition: color $trans-default;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
    }

    @media (hover: hover),
      screen and (min-width: 0\0) {
      &:hover,
      &:active,
      &:focus {
        color: $color-dark-cerulean;
      }
    }
  }

  .date {
    font-size: 12px;
    margin-top: auto;

    &__icon {
      width: 12px;
      height: 12px;
    }
  }

  &__image {
    flex: 1 0 9px;
    position: relative;
    background-color: $color-whisper;
    overflow: hidden;

    @include vp-1023 {
      flex: 1 0 12px;
    }

    @include vp-767 {
      order: -1;
      flex: none;
      min-height: 232px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include vp-767 {
        height: 100%;
      }
    }
  }

  &__tag {
    position: absolute;
    top: 12px;
    left: 12px;

    margin: 0;
    padding: 4px 7px 5px;

    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    color: $color-default-white;
    background-color: $color-dark-cerulean;
    transition: opacity $trans-default;

    @include vp-767 {
      padding: 4px 6px;
    }
  }

  &--sm {
    border-radius: 0;
    min-height: unset;

    .news-card__wrapper {
      padding: 13px 20px 14px;
    }

    .title {
      margin-top: 3px;
      margin-bottom: 5px;
      font-size: 14px;

      @include vp-1023 {
        font-size: 12px;
      }
    }
  }

  &--mobile-big-text {
    .news-card__wrapper {
      @include vp-1023 {
        padding: 13px 13px 14px;
      }
    }

    .title {
      font-size: 14px;
      line-height: 18px;

      @include vp-1023 {
        font-size: 14px;
        margin: 5px 0;
      }
    }
  }

  &--lg {
    min-height: 317px;

    .title {
      font-size: 19px;
    }

    .news-card__text {
      font-size: 16px;

      @include vp-1023 {
        font-size: 14px;
      }

      @include vp-767 {
        font-size: 12px;
      }
    }
  }

  &--photo-top {
    display: flex;
    flex-direction: column;

    box-shadow: 0 4px 15px rgba(48, 48, 48, 0.01);
    border-radius: 2px;
    min-width: 300px;

    @include vp-1919 {
      min-width: 265px;
    }

    @include vp-1439 {
      min-width: 100%;
    }

    .news-card {
      &__image {
        order: -1;
        min-height: 242px;
        flex: none;

        @include vp-767 {
          min-height: 235px;
        }

        img {
          min-height: 242px;
          object-fit: cover;
          width: 100%;

          @include vp-767 {
            min-height: 235px;
          }
        }
      }

      &__wrapper {
        justify-content: space-between;
        min-height: 190px;

        @include vp-1023 {
          max-width: 100%;
        }

        @include vp-767 {
          min-height: 120px;
        }
      }
    }
  }
}
