.accordion {
  &__content {
    transition: max-height $trans-default;
  }

  &__button {
    padding: 0;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    text-align: start;

    @include vp-1023 {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    svg {
      display: none;

      @include vp-767 {
        display: inline-block;
        margin-left: 0;
        width: 10px;
        height: 6px;
      }
    }
  }

  &__element.is-active > &__button {
    svg {
      transform: rotate(180deg) translateY(1px);

      > use {
        fill: transparent;
      }
    }
  }
}
