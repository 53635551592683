.inner-navigation {
  color: $color-bali-hai;
  width: 23%;
  background-color: $color-default-white;
  transform: translate(-1px, 4px);

  @include vp-1023 {
    max-width: unset;
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 16px 8px 8px 36px;

    @include vp-1023 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 16px 8px 8px 19px;
    }
  }

  &__item {
    margin: 0 0 10px 0;
    position: relative;

    @include vp-1023 {
      margin: 0 35px 15px 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 41%;
      left: -17px;
      width: 6px;
      height: 6px;
      background-color: $color-bali-hai;
      border-radius: 50%;
      transition: background-color $trans-default;

      @include vp-1023 {
        top: 38%;
        left: -13px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .inner-navigation__link {
          color: $color-dark-cerulean;
        }

        &::before {
          background-color: $color-alizarin;
        }
      }
    }
  }

  &__item--active {
    .inner-navigation__link {
      color: $color-dark-cerulean;
    }

    &::before {
      background-color: $color-alizarin;
    }
  }

  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    font-family: $ff-ubuntu;
    color: $color-bali-hai;
    transition: color $trans-default;
  }
}
