.main-nav {
  flex-grow: 1;
  display: flex;
  margin-right: 79px;

  @include vp-1279 {
    margin-right: 49px;
  }

  @include vp-1023 {
    flex-grow: 0;
    margin-right: 20px;
  }

  &__wrapper {
    flex-grow: 1;

    @include vp-1023 {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color-dark-cerulean, 0.7);
      z-index: 30;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: opacity $trans-default;

      &--active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    margin-left: -20px;
    margin-top: -20px;
    padding: 0;

    list-style: none;

    @include vp-1023 {
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
      min-height: calc(100 * var(--vh, 1vh));
      height: 100%;
      width: 280px;
      margin: 0;
      padding: 20px 15px;
      background-color: $color-default-white;
      overflow-x: hidden;
      overflow-y: auto;
      //-webkit-overflow-scrolling: touch;
    }
  }

  &__item {
    position: relative;
    margin-left: 20px;
    margin-top: 20px;

    svg {
      will-change: transform;
    }

    &.is-active {
      svg {
        transform: rotate(180deg);
      }
    }

    @include vp-1023 {
      margin: 0 0 24px;

      &.is-active {
        margin-bottom: 16px;

        .main-nav__button {
          color: $color-dark-cerulean;
        }
      }
    }
  }

  &__button {
    position: relative;
    padding: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    color: $color-default-white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: opacity $trans-default;

    @include vp-1023 {
      display: flex;
      align-items: center;
      width: 100%;
      color: $color-nero;
    }

    &--active {
      &::after {
        position: absolute;
        content: "";
        width: 32px;
        height: 2px;
        left: 50%;
        bottom: -12px;
        transform: translateX(-50%);
        background-color: $color-default-white;
        border-radius: 5px 5px 0 0;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 10px;
      height: 7px;
      margin-left: 7px;
      fill: none;
      transition: transform $trans-default;

      @include vp-1023 {
        margin-left: auto;
      }
    }
  }

  &__sublevel-wrapper {
    position: absolute;
    width: 230px;
    top: 34px;
    left: -15px;
    background-color: $color-alice-blue;
    box-shadow: 0 4px 18px rgba(48, 48, 48, 0.25);
    border-radius: 2px;
    z-index: 5;

    @include vp-1023 {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__sublevel-list {
    position: relative;
    margin: 0;
    padding: 15px;
    list-style: none;

    @include vp-1023 {
      padding: 10px 0 0;
    }

    &::after {
      position: absolute;
      content: "";
      width: 24px;
      height: 20px;
      top: 0;
      right: 0;
      background: $blue-to-bottom-right;

      @include vp-1023 {
        display: none;
      }
    }
  }

  &__sublevel-item {
    position: relative;
    padding-left: 17px;
    margin-bottom: 3px;
    cursor: pointer;
    z-index: 10;

    @include vp-1023 {
      margin-bottom: 7px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      .main-nav__sublevel-link {
        color: $color-dark-cerulean;
      }

      &::before {
        background-color: $color-dark-cerulean;
      }
    }

    &:active {
      opacity: 0.8;
    }

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 9px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-alizarin;
      transition: background-color $trans-default;
    }
  }

  &__sublevel-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    user-select: none;
    transition: color $trans-default;
  }

  &__toggle {
    @include expand-interactive-area(34px);

    display: none;

    @include vp-1023 {
      position: relative;
      display: block;
      padding: 0;
      width: 20px;
      height: 16px;
      border: none;
      background-color: $color-transparent;
      cursor: pointer;

      &--open {
        position: absolute;
        z-index: 50;
        left: 287px;
        top: 11px;
      }
    }
  }

  &__toggle-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-default-white;
    border-radius: 1px;

    &--top {
      top: 0;
    }

    &--center {
      top: 50%;
      transform: translateY(-50%);
    }

    &--bottom {
      bottom: 0;
    }

    .main-nav__toggle--open & {
      &--top {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      &--center {
        opacity: 0;
      }

      &--bottom {
        bottom: 50%;
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }
}
