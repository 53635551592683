.partners {
  position: relative;
  background-color: $color-alice-blue;
  padding-top: 71px;
  padding-bottom: 99px;

  @include vp-767 {
    padding: 39px 0 40px;
  }

  &__bg {
    position: absolute;
    bottom: -6px;
    right: 0;
    pointer-events: none;

    svg {
      width: 356px;
      height: 300px;
    }

    @include vp-767 {
      display: none;
    }
  }

  &__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin: 0;
    margin-top: 30px;
    padding: 0;
    list-style: none;
    z-index: 2;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 8px;
      margin-top: 12px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    background-color: $color-default-white;
    box-shadow: 0 4px 15px rgba(48, 48, 48, 0.01);
    border-radius: 2px;
    cursor: pointer;
    transition: box-shadow $trans-default;

    &:hover,
    &:focus {
      box-shadow: 0 4px 15px rgba(48, 48, 48, 0.14);
    }

    @include vp-767 {
      height: 70px;
      min-height: auto;
      padding: 14px 30px;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  &__link {
    img {
      width: 230px;
      height: 60px;
      object-fit: contain;
    }

    @include vp-767 {
      height: 100%;
    }
  }
}
