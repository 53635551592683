.topic {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 19px;
  line-height: 100%;
  font-family: $ff-ubuntu;
  background-image: url("../img/content/documents/img-title-background.png");
  background-color: $color-dark-cerulean;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  margin: 0;
  padding: 10px 20px;
  color: $color-default-white;

  @include retina {
    background-image: url("../img/content/documents/img-title-background@2x.png");
  }

  @include vp-1023 {
    background-position: 80% 0;
    font-size: 15px;
  }

  @include vp-767 {
    width: 100%;
    background-image: url("../img/content/documents/img-title-background-mobile.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    padding: 12px 12px;

    @include retina {
      background-image: url("../img/content/documents/img-title-background-mobile@2x.png");
    }
  }

  &--light {
    background-image: url("../img/content/news-topic-bg.png");

    @include retina {
      background-image: url("../img/content/news-topic-bg@2x.png");
    }

    @include vp-767 {
      background-image: url("../img/content/news-topic-bg-mob.png");
    }
  }

  &--short {
    background-image: url("../img/content/news-topic-bg-mob.png");

    @include retina {
      background-image: url("../img/content/news-topic-bg-mob@2x.png");
    }
  }
}
