.video {
  display: flex;
  height: min-content;

  @include vp-1023 {
    flex-direction: column;
  }

  &__frame {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    cursor: pointer;

    margin-right: 28px;
    margin-bottom: auto;

    @include vp-1023 {
      margin-right: 0;
      margin-bottom: 10px;
    }


    iframe {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border: none;
      outline: none;
      border-radius: 2px;
      overflow: hidden;
    }
  }

  &__links {
    width: 100%;
    max-width: 412px;
    max-height: 478px;
    overflow-y: scroll;

    @include vp-1279 {
      max-width: 300px;
      max-height: 400px;
    }

    @media (max-width: 1100px) {
      max-height: 350px;
    }

    @include vp-1023 {
      max-width: 100%;
      max-height: 283px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 3px;
      background-color: rgba($color-bali-hai, 0.45);
    }

    &::-webkit-scrollbar {
      background-color: $color-transparent;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-transparent;
    }
  }

  &__links-list {
    margin: 0;
    padding: 0;
    padding-left: 24px;
    list-style: none;

    @include vp-1023 {
      padding-left: 0;
    }
  }

  &__links-item {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    padding: 18px 0 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transform: opacity $trans-default;
    cursor: pointer;
    user-select: none;

    @include vp-1279 {
      padding: 12px 0;
      font-size: 14px;
    }


    &:first-child {
      padding-top: 3px;

      &.video__links-item--active::before {
        top: 10px;
      }

      @include vp-1023 {
        padding-top: 0;

        &.video__links-item--active::before {
          top: 6px;
        }
      }
    }

    .date {
      margin-top: 12px;
      font-size: 12px;
      line-height: 12px;
      color: $color-default-white;
      opacity: 0.6;
      transition: opacity $trans-default;

      @include vp-1023 {
        margin-top: 7px;
      }
    }

    &--active {
      pointer-events: none;

      .video__link,
      .date {
        opacity: 1;
      }


      &::before {
        position: absolute;
        content: "";
        top: 25px;
        left: -19px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-default-white;
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.35);
      }

      @include vp-1023 {
        .video__link {
          padding-left: 17px;
        }

        &::before {
          width: 5px;
          height: 5px;
          left: 3px;
          top: 18px;
        }
      }
    }

    &:hover {
      .video__link,
      .date {
        opacity: 1;
      }
    }
  }

  &__link {
    color: $color-default-white;
    opacity: 0.6;
    transition: opacity $trans-default;
  }
}
