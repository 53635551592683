.main-advantages {
  &__list {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }

    @include vp-767 {
      margin-top: 40px;
      margin-bottom: 24px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex: 0 1 301px;
    min-height: 140px;
    background-color: $color-alice-blue;
    padding: 20px;
    padding-right: 27px;

    @include vp-767 {
      flex-direction: column;
      padding-right: 11px;
      padding-left: 11px;
      padding-top: 13px;
    }

    &::before {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-style: solid;
      border-width: 0 20px 20px 0;
      border-color: transparent rgba($color-bali-hai, 0.2) transparent transparent;
    }

    p {
      font-family: $ff-ubuntu;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $color-matterhorn;
      margin: 0;
      margin-top: 8px;

      @include vp-767 {
        font-size: 14px;
        line-height: 17px;
        margin-top: 0;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    margin-left: 15px;
    width: 100%;

    @include vp-767 {
      margin-left: 0;
    }
  }

  &__title {
    font-family: $ff-ubuntu;
    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-dark-cerulean;

    @include vp-767 {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
