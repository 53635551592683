.info-block {
  padding: 30px;
  background-color: $color-default-white;

  @include vp-767 {
    padding: 19px 11px;
  }

  p {
    margin: 0;

    @include vp-767 {
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  &__inner {
    display: flex;
    margin-bottom: 20px;

    @include vp-767 {
      margin-bottom: 15px;
    }
  }

  &__text {
    font-weight: 500;
  }

  &__icon {
    margin-left: 8px;
    width: 40px;
    height: 40px;
    color: $color-pattens-blue;

    @include vp-767 {
      display: none;
    }
  }

  .btn {
    @include vp-767 {
      width: 100%;
    }
  }
}
