.courses {
  &__navigation {
    margin: 0 0 19px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include vp-767 {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__link {
    display: block;
    cursor: pointer;
    margin: 0 22px 10px 0;
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: $color-bali-hai;
    font-family: $ff-ubuntu;
    transition: color $trans-default;
    position: relative;

    @include vp-1023 {
      margin: 0 18px 18px 0;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-dark-cerulean;
      }

      &:focus {
        color: $color-dark-cerulean;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $color-dark-cerulean;
          bottom: -2px;
          left: 0;
        }
      }
    }

    span {
      margin: 0 0 0 3px;
    }
  }

  &__cards {
    width: 100%;
  }

  &__element {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @include vp-1023 {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}

.courses__link.is-active {
  position: relative;
  color: $color-dark-cerulean;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $color-dark-cerulean;
    bottom: -2px;
    left: 0;
  }
}
