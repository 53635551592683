.main-news {
  &__top-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 1100px) {
      column-gap: 15px;
      margin-bottom: 15px;
    }

    @include vp-1023 {
      grid-template-columns: 1fr;
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-bottom: 15px;
      margin-top: 14px;
    }

    div:first-of-type {
      @include vp-767 {
        height: 360px;
      }
    }
  }

  &__slider {
    position: relative;
    overflow: hidden;
    max-width: 630px;

    @include vp-1439 {
      max-width: 100%;
    }

    @include vp-767 {
      margin-bottom: 15px;
    }
  }

  &__slider-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition-property: transform;
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);
  }

  &__slide {
    width: 630px;
    min-width: 630px;
    min-height: 430px;
    position: relative;
    transition-property: transform;

    @include vp-1439 {
      width: 100%;
      min-width: 100%;
    }

    @include vp-767 {
      min-height: 360px;
    }
  }

  &__navigation {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $color-alizarin;

    @include vp-767 {
      top: 0;
      bottom: auto;
    }
  }

  &__slider-button {
    cursor: pointer;
    width: 44px;
    height: 44px;
    background-color: $color-alizarin;
    color: $color-default-white;
    border: none;
    transition: background-color $trans-default;

    &:hover {
      background-color: $color-coral-red;
    }

    svg {
      width: 13px;
      height: 10px;
      fill: transparent;
    }
  }

  &__large-cards-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__large-cards-item {
    @include vp-1023 {
      width: 100%;
    }
  }

  &__bottom-container {
    display: grid;
    grid-template-columns: 3fr 0.95fr;
    column-gap: 30px;

    @media (max-width: 1100px) {
      grid-template-columns: 3fr 0.96fr;
      column-gap: 15px;
    }

    @include vp-1023 {
      grid-template-columns: 2fr 1fr;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  &__side-news {
    display: flex;
    flex-direction: column;
    position: relative;
    background: $color-default-white;
    box-shadow: 0 4px 15px rgba(48, 48, 48, 0.01);
    border-radius: 2px;
    max-height: 430px;

    @include vp-1439 {
      max-height: calc(100% - 31px);
    }

    @include vp-1023 {
      max-height: 100%;
    }

    @include vp-767 {
      height: 430px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $color-default-white 100%);
      border-radius: 2px;

      @include vp-1023 {
        max-width: 100%;
      }
    }
  }

  &__side-news-controls {
    padding: 12px 20px;


    @include vp-767 {
      padding: 7px 12px;
      padding-bottom: 12px;
    }
  }

  &__side-news-control {
    display: inline-flex;
    position: relative;
    font-family: $ff-ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $color-bali-hai;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-right: 16px;
    transition: color $trans-default;

    @include vp-1023 {
      margin-right: 9px;

      &:last-child {
        margin-right: 0;
      }
    }

    &.is-active {
      color: $color-dark-cerulean;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        bottom: -6px;
        left: 0;
        background-color: $color-dark-cerulean;
      }
    }

    &:hover,
    &:focus {
      cursor: pointer;
      color: $color-dark-cerulean;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    @include vp-767 {
      margin-top: 12px;
    }
  }

  &__button {
    min-width: 220px;

    @include vp-767 {
      width: 100%;
    }
  }
}

.main-news-slide {
  .on-photo-label {
    @include vp-767 {
      margin-right: 100px;
    }
  }
}

.main-news-large-card {
  &__img-wrapper {
    img {
      height: 430px;

      @include vp-1023 {
        width: 100%;
      }
    }
  }
}

.main-news-slide,
.main-news-large-card {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    max-width: 645px;
    height: 200px;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 100%);
    border-radius: 2px;
    z-index: 1;

    @include vp-1023 {
      max-width: 100%;
    }
  }

  &__img-wrapper {
    min-height: 430px;

    @include vp-767 {
      position: relative;
      min-height: 360px;
    }

    img {
      object-fit: cover;
      height: 430px;

      @include vp-1023 {
        width: 100%;
      }

      @include vp-767 {
        position: absolute;
        height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 630px;
    height: 100%;
    z-index: 2;
    margin-left: 17px;

    @include vp-1439 {
      max-width: 85%;
    }

    @include vp-1023 {
      max-width: 100%;
    }

    @include vp-767 {
      margin-left: 12px;
    }
  }

  &__category {
    margin-top: 12px;
    margin-left: -5px;
    margin-bottom: auto;

    @include vp-767 {
      margin-left: 0;
    }
  }

  &__title {
    font-family: $ff-ubuntu;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    color: $color-default-white;
    margin-bottom: 22px;
    width: 85%;
    max-width: 630px;

    @include vp-1023 {
      max-width: 100%;
    }

    @include vp-767 {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 12px;
      margin-top: 5px;
    }
  }

  .date {
    color: $color-default-white;
  }
}

.main-news__tabs-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .custom-scrollbar {
    flex: none;
    height: 0;

    &.is-active {
      height: 1;
      flex-grow: 1;
    }
  }
}
