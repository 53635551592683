.footer-safety {
  padding: 119px 0 105px;
  color: $color-default-white;
  background-color: $color-tangaroa;
  background-image: url("../img/content/footer/safety-bg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

  @include retina {
    background-image: url("../img/content/footer/safety-bg@2x.png");
  }

  @include vp-767 {
    padding: 40px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 50px;
    line-height: 1;

    @include vp-1439 {
      font-size: 42px;
    }

    @include vp-1023 {
      font-size: 36px;
    }

    @include vp-767 {
      margin-bottom: 16px;
      font-size: 22px;
    }
  }

  &__description {
    margin: 0;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    text-transform: uppercase;

    @include vp-1279 {
      max-width: 550px;
    }

    @include vp-767 {
      max-width: 100%;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: list-counter;
  }

  &__item {
    position: relative;
    margin-bottom: 14px;
    padding-left: 35px;
    font-size: 16px;
    line-height: 1.3;
    counter-increment: list-counter;

    @include vp-767 {
      padding-left: 33px;
      margin-bottom: 13px;
      font-size: 14px;
    }

    &::before {
      position: absolute;
      content: counter(list-counter);
      left: -1px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
      font-weight: 500;
      font-size: 14px;
      line-height: 0.8;
      color: $color-tangaroa;
      background-color: $color-default-white;
      border-radius: 50%;
    }
  }

  &__text-content {
    max-width: calc(50% - 30px);

    @include vp-767 {
      max-width: 100%;
    }
  }

  &__slogan {
    max-width: calc(50% - 30px);

    @include vp-767 {
      max-width: 100%;
      margin-left: 0;
    }
  }

  &__slogan-img {
    width: 524px;
    max-width: 100%;

    @include vp-767 {
      margin: 0 auto;
    }

    img {
      width: 524px;
      height: 170px;
      object-fit: contain;
    }
  }

  &__slogan-top {
    margin: 14px 0;
    font-weight: 700;
    font-size: 19px;
    line-height: 1.2;
    text-align: center;

    @include vp-767 {
      margin-top: 28px;
      font-size: 16px;
    }

    @include vp-474 {
      text-align: left;
    }

    span {
      font-weight: 400;
    }
  }

  &__slogan-bottom {
    margin: 0;
    margin-top: 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-align: center;


    @include vp-767 {
      margin-top: 15px;
    }
  }
}
