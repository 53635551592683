.page-intro {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 30px 0;
  color: $color-default-white;
  overflow: hidden;

  @include vp-767 {
    padding-top: 15px;
    padding-bottom: 29px;
  }

  .title {
    margin-top: 7px;
    margin-bottom: 20px;

    @include vp-767 {
      margin-bottom: 23px;
    }
  }

  .container {
    z-index: 1;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $color-dark-cerulean;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-nero-2, 0.58);
      pointer-events: none;
    }

    @include vp-767 {
      display: flex;
      justify-content: flex-end;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      margin: 0 auto;

      @include vp-767 {
        width: 767px;
        max-width: unset;
        height: 420px;
      }
    }
  }

  .btn {
    @include vp-1023 {
      width: 100%;
      max-width: 290px;
    }
  }

  &--large {
    min-height: 545px;

    @include vp-1023 {
      min-height: 420px;
    }

    .title {
      margin-top: 0;
      margin-bottom: 30px;

      @include vp-767 {
        margin-bottom: 30px;
        margin-top: 14px;
      }
    }
  }

  &--study {
    padding: 30px 0 10px;
  }
}
