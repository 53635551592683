.news-aside {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0;
    border-bottom: 1px solid rgba($color-bali-hai, 0.15);
  }
}
