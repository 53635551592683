.doc-files {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__item {
    margin: 0 0 23px;

    @include vp-767 {
      margin: 0 0 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      margin: 0 0 15px 0;

      @include vp-767 {
        margin: 0 0 11px 0;
      }
    }
  }
}
