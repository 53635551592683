.folders {
  &__element {
    margin: 0 0 9px 0;

    @include vp-767 {
      margin: 0 0 11px;
    }
  }

  &__button {
    cursor: pointer;
    position: relative;
    border: none;
    display: flex;
    align-items: center;
    padding: 12px 52px;
    margin: 0;

    background-color: $color-default-white;
    box-shadow: 0 4px 15px rgba(48, 48, 48, 0.01);
    border-radius: 2px;
    width: 100%;

    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: $ff-ubuntu;
    color: $color-nero;
    transition: color $trans-default;

    svg:first-of-type {
      position: absolute;
      width: 20px;
      height: 18px;
      left: 18px;
      color: $color-bali-hai;
      fill: none;
      transition: color $trans-default;

      @include vp-767 {
        left: 13px;
      }
    }

    svg:last-of-type {
      display: none;
    }

    @media (hover: hover),
      screen and (min-width: 0\0) {
      &:hover {
        color: $color-dark-cerulean;

        svg:first-of-type {
          color: inherit;
        }
      }
    }

    @include vp-767 {
      font-size: 14px;
      padding: 11px 40px;
    }
  }

  .folders__element.is-active {
    .folders__button {
      border-bottom: 1px solid $color-bali-hai-03;
      color: $color-alizarin;
      position: relative;

      svg:first-of-type {
        display: none;
      }

      svg:last-of-type {
        display: block;
        position: absolute;
        left: 18px;
        width: 20px;
        height: 18px;
        color: inherit;
        fill: none;

        @include vp-767 {
          left: 13px;
        }
      }
    }
  }

  &__content {
    padding: 0;
    margin: 0;
    background-color: $color-default-white;
  }
}
