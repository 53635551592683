.profservice {
  position: relative;
  padding-top: 100px;
  padding-bottom: 119px;

  @include vp-767 {
    padding: 37px 0 42px;
  }

  &__bg {
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: -2;
    height: 100%;

    &--right {
      right: 0;


      svg {
        width: 243px;
        height: 100%;
        object-position: left;
      }
    }

    &--left {
      left: 0;

      img {
        width: 263px;
        height: 100%;
        object-fit: cover;
        object-position: right;
      }

      @include vp-1919 {
        left: -70px;
      }

      @include vp-1439 {
        display: none;
      }
    }

    @include vp-767 {
      display: none;
    }
  }

  &__title {
    margin: 0;
    font-family: $ff-ubuntu;
    font-weight: 700;
    font-size: 25px;
    line-height: 1;
    color: $color-nero;

    @include vp-767 {
      font-size: 22px;
    }
  }

  &__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin: 0;
    margin-top: 30px;
    padding: 0;
    list-style: none;
    z-index: 2;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 8px;
      margin-top: 16px;
    }
  }

  &__item {
    min-height: 90px;
    padding: 25px 20px;
    border-radius: 2px;
    background: $color-alice-blue;
    box-shadow: 0 4px 15px rgba(48, 48, 48, 0.01);
    transition: box-shadow $trans-default;

    @include vp-767 {
      min-height: 85px;
      padding: 23px 12px;
    }

    &:hover,
    &:focus {
      box-shadow: 0 4px 15px rgba(48, 48, 48, 0.14);

      .profservice__button {
        background-color: $color-alizarin;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__linf-icon {
    width: 40px;
    height: 40px;
  }

  &__item-name {
    margin: 0;
    margin-left: 18px;
    margin-right: 15px;
    width: fit-content;
    font-family: $ff-ubuntu;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    color: $color-nero;

    @include vp-767 {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__button {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-left: auto;
    color: $color-default-white;
    background-color: $color-dark-cerulean;
    border-radius: 50%;
    transition: background-color $trans-default;

    svg {
      width: 13px;
      height: 10px;
      fill: none;
    }
  }
}
