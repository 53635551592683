.blockquote {
  position: relative;
  margin: 0;
  padding: 28px 30px;
  font-weight: 500;
  background-color: $color-default-white;

  @include vp-767 {
    margin: 0;
    padding: 26px 12px;
  }

  &__wrapper {
    display: flex;
    margin: 0 0 20px;

    @include vp-767 {
      margin: 0 0 14px;
    }
  }

  &__caption {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 12px;

    @include vp-767 {
      align-items: unset;
      padding: 5px 0;
    }
  }

  &__text {
    line-height: 1.5;

    @include vp-767 {
      font-size: 15px;
      line-height: 1.4;
    }
  }

  p {
    margin: 0;

    @include vp-767 {
      margin: 0;
    }
  }

  &__icon {
    margin-left: 16px;
    flex-shrink: 0;
    width: 40px;
    height: 36px;

    @include vp-767 {
      position: absolute;
      top: 14px;
      right: 6px;
      margin-left: 16px;

      width: 17px;
      height: 16px;
    }
  }

  &__avatar {
    display: inline-flex;
    flex-shrink: 0;
    margin-right: 14px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;

    @include vp-767 {
      margin-right: 10px;
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 22px;

    @include vp-767 {
      font-size: 12px;
      line-height: 1;
    }

    span {
      display: block;
    }
  }

  &__titul {
    font-weight: 400;
    color: $color-bali-hai;
  }
}
