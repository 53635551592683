.header {
  position: relative;

  &__top {
    padding: 10px 0 8px;

    @include vp-1023 {
      padding: 3px 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include vp-1023 {
        flex-direction: row-reverse;
      }
    }
  }

  &__logo {
    margin-right: 8%;

    @include vp-1023 {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      width: 370px;
      height: 92px;

      @include vp-1023 {
        width: 230px;
        height: 56px;
      }
    }
  }

  &__btn-bvi.btn {
    padding: 12px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    @include vp-1023 {
      @include expand-interactive-area(45px);

      padding: 0;
      font-size: 0;
      background-color: transparent;
      transition: opacity $trans-default;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-dark-cerulean;
          background-color: transparent;
          opacity: 0.8;
        }
      }
    }

    svg {
      width: 22px;

      @include vp-1023 {
        margin-right: 0;
      }
    }
  }

  &__btn-login {
    &--desktop.btn {
      min-width: 170px;
      margin-bottom: 2px;
      padding: 16px 0 13px;

      @include vp-1023 {
        display: none;
      }
    }


    &--mobile.btn {
      display: none;

      @include vp-1023 {
        display: inline-flex;
        width: fit-content;
        margin-left: auto;
        padding: 10px 0;
        font-weight: 700;
        font-size: 13px;
        line-height: 1;
        background-color: transparent;
        transition: opacity $trans-default;

        @media (hover: hover), screen and (min-width: 0\0) {
          &:hover,
          &:focus {
            opacity: 0.8;
          }
        }
      }
    }

    svg {
      width: 15px;
      fill: none;

      @include vp-1023 {
        order: 2;
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  &__bottom {
    padding: 9px 0;
    background-color: $color-dark-cerulean;

    @include vp-1023 {
      padding: 0;
    }

    .container {
      display: flex;
      align-items: flex-start;

      @include vp-1023 {
        align-items: center;
      }
    }
  }

  &__btn-search.btn {
    padding: 3px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    background: transparent;
    transition: background-color $trans-default, opacity $trans-default;

    @include vp-1023 {
      @include expand-interactive-area(36px);
      font-size: 0;
    }

    svg {
      width: 18px;
      fill: none;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: transparent;
        opacity: 0.8;
      }
    }
  }
}
