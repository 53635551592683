.date {
  font-size: 14px;
  line-height: 1.2;
  color: $color-bali-hai;

  @include vp-767 {
    font-size: 12px;
  }

  svg {
    vertical-align: baseline;
    margin-right: 3px;
    width: 10px;
    height: 10px;

    @include vp-767 {
      margin-right: 6px;
    }
  }
}
