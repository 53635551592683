.pagination {
  display: flex;
  align-items: center;
  align-self: center;
  grid-column: 1 / -1;
  justify-content: center;
  margin-top: 8px;

  &__list {
    margin: 0 14px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  &__nav-link {
    svg {
      fill: none;
      color: $color-bali-hai;
      transition: color $trans-default;

      @media (hover: hover),
        screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-dark-cerulean;
        }
      }
    }
  }

  &__nav-link--back {
    svg {
      width: 16px;
      height: 13px;
    }
  }

  &__nav-link--forward {
    svg {
      width: 17px;
      height: 13px;
    }
  }

  &__link {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
    font-family: $ff-ubuntu;
    background-color: $color-default-white;
    color: $color-bali-hai;

    margin: 0 10px 0 0;
    padding: 6px 0 0 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;

    transition: color $trans-default, background-color $trans-default;
  }

  &__link:not(:nth-last-child(2)) {
    @media (hover: hover),
      screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-default-white;
        background-color: $color-dark-cerulean;
        transition: color $trans-default, background-color $trans-default;
      }
    }
  }

  &__link:nth-last-child(2) {
    background-color: transparent;
    width: auto;
    height: auto;

    @media (hover: hover),
      screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-default-black;
        transition: color $trans-default;
      }
    }
  }

  &__link:last-child {
    margin: 0;
  }

  &__link--active {
    color: $color-default-white;
    background-color: $color-dark-cerulean;
    transition: color $trans-default, background-color $trans-default;
  }
}
