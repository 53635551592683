.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px 35px;

  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;

  color: $color-default-white;

  background-color: $color-alizarin;
  border: none;
  border-radius: 2px;
  cursor: pointer;

  transition: background-color $trans-default;

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-coral-red;
    }
  }

  svg {
    width: 15px;
    height: 16px;
    margin-right: 10px;
    fill: none;
  }

  &--blue {
    color: $color-dark-cerulean;
    background-color: $color-alice-blue;
    box-shadow: 0 4px 15px rgba(48, 48, 48, 0.01);
    transition: background-color $trans-default, color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-alizarin;
        color: $color-default-white;
      }
    }
  }
}
